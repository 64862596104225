import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Container, Typography } from "@material-ui/core";

import HeaderImage from "../assets/images/header-image.png";

const useStyles = makeStyles((theme) => ({
  font: {
    fontFamily: ["Ubuntu", "sans-serif"].join(","),
  },
  header: {
    width: "auto",
    height: "auto",
  }
}));

export default function Header() {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.header} ml={-0.5}>
        <img src={HeaderImage} width="100%" height="100%" alt="" />
      </Box>
      <Box my={5} ml={10} align="left">
        <Container maxWidth="lg">
          <Typography variant="h4" style={{ color: "#322B6D", fontWeight: "700" }} gutterBottom className={classes.font}>
            WHAT WE OFFER
          </Typography>
        </Container>
      </Box>
    </>
  );
}
