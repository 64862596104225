import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Card, Typography, Box } from '@material-ui/core'

import visa from '../assets/images/visa.svg'
import paypal from '../assets/images/paypal.svg'
import mastercard from '../assets/images/mastercard.svg'
import googlepay from '../assets/images/googlepay.svg'
import iconPayment from "../assets/images/icon-payment.svg"

const useStyles = makeStyles((theme) => ({
  cardSize: {
    width: "400px",
    height: "150px",
    boxShadow: "2px 4px 15px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
  },
  greenPay: {
    border: "5px solid #37B34A",
    borderRadius: "5px",
    width: "350px",
    height: "60px",
  },
}))

export default function PaymentCard() {
  const classes = useStyles()

  const iconsPayment = [
    { src: mastercard, alt: "Mastercard", size: "35px" },
    { src: paypal, alt: "Paypal", size: "30px" },
    { src: googlepay, alt: "Googlepay", size: "25px" },
    { src: visa, alt: "Visa", size: "25px" },
  ]

  return (
    <Box my={7} pb={6}>
      <Card className={classes.cardSize}>
        <Box display="flex" justifyContent="center" my={2}>
          <Box mt={-0.8} mr={1}>
            <img src={iconPayment} alt="" />
          </Box>
          <Typography variant="subtitle1" style={{ color: "#000000", fontWeight: "600" }}>Secured payment</Typography>
        </Box>
        <Box my={2}>
          <Card className={classes.greenPay}>
            <Grid container alignItems="center" justify="center" spacing={2}>
              {iconsPayment.map((icon) =>
                <Grid item key={icon.alt}>
                  <Box mt={1} >
                    <img src={icon.src} height={icon.size} alt="" />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Card>
        </Box>
      </Card>
    </Box>
  )
}
