import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Container, Card, Typography, Box, Button, Link } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  size: {
    background: 'linear-gradient(180deg, #71389E 0%, #9553B3 49.65%, #B96FC8 100%)',
    boxShadow: "2px 4px 15px rgba(0, 0, 0, 0.25)",
    borderRadius: "15px",
    maxWidth: "315px",
    minHeight: "400px",
  },
  buttonPurchase: {
    width: "150px",
    height: "50px",
    borderRadius: "10px",
    backgroundColor: "#FFFFFF",
    color: "#000000",
    fontWeight: "600",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#DCDCDC",
      color: "#000000",
    },
  },
}));

export default function Pricing() {
  const classes = useStyles()

  const cards = [
    {
      title: "free offer",
      price: "Free",
      text: ["FREE <3", "Basic features for casual players"],
      button: "TRY IT",
      link: "https://community.raidbot.app/index.php?/store/product/11-free-license/"
    },
    {
      title: "regular offer",
      price: "From 5€ / week",
      text: ["Support our dev <3", "All standard features for advanced player"],
      button: "PURCHASE",
      link: "https://community.raidbot.app/index.php?/store/product/3-regular-license/"
    },
    {
      title: "premium offer",
      price: "From 7€ / week",
      text: ["Access to beta features", "Endgame features for elite players"],
      button: "PURCHASE",
      link: "https://community.raidbot.app/index.php?/store/product/9-premium-license/"
    }
  ]

  return (
    <>
      <Box my={5} ml={10} align="left">
        <Container maxWidth="lg">
          <Typography
            variant="h4"
            style={{ color: "#322B6D", fontWeight: "700" }}
            gutterBottom
            className={classes.font}
          >
            STORE
          </Typography>
        </Container>
      </Box>
      <Container maxWidth="lg">
        <Grid container alignItems="center" justify="center" spacing={2}>
          {cards.map((item) =>
            <Grid item xs={12} sm={6} md={4} xl={4} lg={4} key={item.title}>
              <Card className={classes.size}>
                <Box mt={3}>
                  <Grid container direction="column" alignItems="center" spacing={5}>
                    <Grid item>
                      <Typography variant="subtitle1" style={{ color: "#FFFFFF", fontWeight: "600" }}>{item.title.toUpperCase()}</Typography>
                    </Grid>
                    <Card style={{ backgroundColor: "#FFFFFF", width: "325px", height: "60px", zIndex: 2, position: "absolute", marginTop: "60px" }}>
                      <Box display="flex" justifyContent="center" mt={2}>
                        <Typography variant="h5" style={{ color: "#71389E", fontWeight: "600" }}>{item.price}</Typography>
                      </Box>
                    </Card>
                    <Box mt={10}>
                      <Grid item>
                        {item.text.map((txt) =>
                          <Box my={3} key={txt}>
                            <Typography variant="body2" style={{ color: "#FFFFFF" }} align="center" gutterBottom>
                              {txt}
                            </Typography>
                          </Box>
                        )}
                      </Grid>
                    </Box>
                    <Grid item>
                      <Button component={Link} underline="none" href={item.link} target="blank" rel="noopener" className={classes.buttonPurchase}>{item.button}</Button>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  )
}
