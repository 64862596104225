import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Box, Container, Typography, Card, Grid } from "@material-ui/core";

import keyboard from '../assets/images/keyboard.svg'
import check from '../assets/images/check.svg'
import group from '../assets/images/group.svg'

const useStyles = makeStyles((theme) => ({
  size: {
    background: "linear-gradient(180deg, #352C71 0%, #743AA0 100%)",
    boxShadow: "2px 4px 15px rgba(0, 0, 0, 0.25)",
    borderRadius: "15px",
    maxWidth: "315px",
    minHeight: "450px",
  },
}));

export default function Cards() {

  const classes = useStyles()
  return (
    <Box my={10}>
      <Container maxWidth="lg">
        <Grid container alignItems="center" justify="center" spacing={2}>
          <Grid item xs={12} sm={6} md={4} xl={4} lg={4} >
            <Card className={classes.size}>
              <Grid container direction="column" alignItems="center" spacing={3} >
                <Grid item xs={12}>
                  <Box mt={5} style={{ height: "140px" }}>
                    <img src={keyboard} height="120px" alt="" />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" style={{ color: "#FFFFFF" }}><span style={{ fontWeight: "600" }}>FARM WHILE AFK</span></Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box style={{ color: "#FFFFFF" }}>
                    <Typography variant="body2" style={{ color: "#FFFFFF", textAlign: "center", fontWeight: "200" }}>Selected <span style={{ fontWeight: "600" }}>Campaign or Dungeons boss </span>
                    </Typography>
                    <Typography variant="body2">Battle Arena and filter by enemy team power</Typography>
                    <Typography variant="body2"><span style={{ fontWeight: "600" }}>Buy</span> Blue/Green shard from market</Typography>
                    <Typography variant="body2"><span style={{ fontWeight: "600" }}>Level heroes</span> in Sparring Pit</Typography>
                    <Typography variant="body2"><span style={{ fontWeight: "600" }}>Level food</span></Typography>
                    <Typography variant="body2">Manage <span style={{ fontWeight: "600" }}>bastion reward</span></Typography>
                    <Typography variant="body2">Fight <span style={{ fontWeight: "600" }}>Clan boss</span></Typography>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} xl={4} lg={4}>
            <Card className={classes.size}>
              <Grid container direction="column" alignItems="center" spacing={3} >
                <Grid item xs={12}>
                  <Box mt={5} style={{ height: "140px" }}>
                    <Box mt={3} clone>
                      <img src={group} height="90px" alt="" />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" style={{ color: "#FFFFFF", fontWeight: "600" }} align="center">BE PART OF A COMMUNITY
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box mx={1}>
                    <Grid container direction="column" alignItems="center" spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="body2" style={{ color: "#FFFFFF" }} align="center">
                          <span style={{ fontWeight: "600" }}>24/7</span> Support
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2" style={{ color: "#FFFFFF" }}>
                          Active forum
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2" style={{ color: "#FFFFFF" }}>
                          Chatbox
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} xl={4} lg={4}>
            <Card className={classes.size}>
              <Grid container direction="column" alignItems="center" spacing={3} >
                <Grid item xs={12}>
                  <Box mt={5} style={{ height: "140px" }}>
                    <Box mt={3} clone>
                      <img src={check} height="90px" alt="" />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" style={{ color: "#FFFFFF", fontWeight: "600" }}>SAFE AND EASY</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box mx={1}>
                    <Typography variant="body2" style={{ color: "#FFFFFF", textAlign: "center" }}>Running inside <span style={{ fontWeight: "600" }}>BlueStacks</span> Android emulator, very hard to detect, never detected since first release.
                      <br />
                      <br />
                      Easy to setup and run.
                      <br />
                      <br />
                      Emulates <span style={{ fontWeight: "600" }}>human behavior</span> to be more secure.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box >
  )
}
