import React from "react"
import Box from "@material-ui/core/Box"
import Header from "../components/Header"
import Navbar from "../components/Navbar"
import Cards from "../components/Cards"
import Pricing from "../components/Pricing"
import PaymentCard from "../components/PaymentCard"
import Footer from "../components/Footer"
import SEO from "../components/SEO"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF"
  },
}));

export default function Index() {
  const classes = useStyles()
  return (
    <>
      <SEO title="RaidBot - Best bot for RAID: Shadow Legends" />
      <Box className={classes.root}>
        <Navbar />
        <Header />
        <Box align="center">
          <Cards />
          <Pricing />
          <PaymentCard />
        </Box>
        <Footer />
      </Box>
    </>
  );
}
